export const showAlert =  (message, context) => {
    message = (message || '').trim();
    /*
    The purpose for this showAlert composable is to display an error message whenever we encounter an error condition.
    Inside this composable, we have logic so that we do not spam the user with alert messages if they happens within certain interval.
    
    This composable is primarily designed to handle when an HTTP error happens.  Currently, we are leveraging the onRequestError, and 
    the onResponseError hooks inside the app.vue file.  These hooks are part of the ofetch library that Nuxt uses.

    This composable is also being used in a few places that are likely to be errors.  For other places where it is not an HTTP error,
    we still use the normal alert.  Do not use this composable for those places.

    This showAlert composable takes 2 parameters: message, context.
    At least one of these parameter is required.  We will use all parameters, if supplied, to derive the final message.

    We can simulate HTTP error messages by:
    1. Stopping req_router and then do something on the page
    2. Stopping taskservice, and then do something on the page
    3. Try to edit a task that you do not have privilege to edit.
    4. Try to execute a task with wrong parameter
    5. Try to execute a task that you do not have privilege
    6. Open a tab, edit a task but don't save it yet, and open another tab, sign-out, switch back to the first tab and save the task
    7. Assignments quoted and default values in the input / output (ParamsList), like editting these things for an existing task or executing a task.
    */

    // We are creating the construct_message_from_http_error_code function as an inner function here, because this function is not 
    // needed anywhere outside of this composable.
    function construct_message_from_http_error_code(http_error_code, context) {
        let try_again_later_message = "Please try again later, or contact your support team.";
        let check_form_parameters_message = "Please check your form parameters, try again later, or contact your support team.";
        let unauthorized_message = "You may be trying to edit a task that you do not have access for.  Please request access by clicking on the edit icon, or contact your support team.";

        let message = ''
        // Currently, standard_or_custom_http_errors_mapping is just a standard http error code => message mapping.  If we need to 
        // change the message if it make sense for our need, we can change it here.
        const standard_or_custom_http_errors_mapping = {
            502: 'Bad gateway',
            503: 'Service Unavailable',
            504: 'Gateway Timeout',

            500: 'Internal Server Error',

            501: 'Not Implemented',
            505: 'HTTP Version Not Supported',
            506: 'Variant Also Negotiates',
            507: 'Insufficient Storage',
            508: 'Loop Detected',
            510: 'Not Extended',
            511: 'Network Authentication Required',

            401: 'Unauthorized',
            403: 'Forbidden',

            400: 'Bad Request',
            402: 'Payment Required',
            404: 'Not Found',
            405: 'Method Not Allowed',
            406: 'Not Acceptable',
            407: 'Proxy Authentication Required',
            408: 'Request Timeout',
            409: 'Conflict',
            410: 'Gone',
            411: 'Length Required',
            412: 'Precondition Failed',
            413: 'Payload Too Large',
            414: 'URI Too Long',
            415: 'Unsupported Media Type',
            416: 'Range Not Satisfiable',
            417: 'Expectation Failed',
            418: "I'm a Teapot",
            421: 'Misdirected Request',
            422: 'Unprocessable Entity',
            423: 'Locked',
            424: 'Failed Dependency',
            425: 'Too Early',
            426: 'Upgrade Required',
            428: 'Precondition Required',
            429: 'Too Many Requests',
            431: 'Request Header Fields Too Large',
            451: 'Unavailable For Legal Reasons'
        } 
        
        let env_state = useEnvState();
        let env = env_state.value;
        let enforce_login = env.enforce_login;

        if ((typeof(enforce_login) !== undefined) && (enforce_login != null)) {
            if (enforce_login.trim().toLowerCase() == "true") {
                enforce_login = true;
            } else {
                enforce_login = false;
            }
        } else {
            enforce_login = false;
        }
    
        if ((enforce_login) && (http_error_code == 401)) {
            return navigateToWrapper('/vlogin');
        } else if (http_error_code == 401) {
            // On the task details page, if the user is not authenticated, we do not want to display 
            // execution results, but we want to display comments anyway, so the back-end should not 
            // send us a 400 series error, but if it does, just fall through below, and we may display 
            // one alert.  If we do not want to display the alert, we can return an empty string here.
            if ((context.hasOwnProperty('request')) && (context.request.includes('/messages'))) {
                return "";
            }
        }

        if ( [500, 502, 503].includes(http_error_code) ) {
            let just_started = false;
            let server_start_time = env.server_start_time;
            let now = (new Date()).getTime() / 1000;
            if (now - parseInt(server_start_time) < 4 * 60) {
                // The server was restarted within 4 minutes
                just_started = true;
            }
            if (just_started) {
                if (window.location.pathname.startsWith('/vlogin')) {
                    return '';
                }    
            }
        }

        if ([500, 400].includes(http_error_code)) {
            message = "Error:  " + standard_or_custom_http_errors_mapping[http_error_code]  + ' (' + http_error_code + ').' + check_form_parameters_message;
        } else if ([401, 403].includes(http_error_code)) {
            message = "Error:  " + standard_or_custom_http_errors_mapping[http_error_code]  + ' (' + http_error_code + ').' + unauthorized_message;
        } else {
            message = "Error:  " + standard_or_custom_http_errors_mapping[http_error_code]  + ' (' + http_error_code + ').' + try_again_later_message;
        }
        return message;
    }
    // End of the construct_message_from_http_error_code inner function

    let last_alert_message_timestamp_state = useLastAlertTimestampState();
    let last_alert_message_timestamp = last_alert_message_timestamp_state.value;
    let interval = 2; // We do not want to display the second alert message if it happens to be within this interval (seconds) from the previous alert message

    let now = Math.floor(Date.now() / 1000); // the now variable hold the timestamp in seconds, not milliseconds since epoch.

    /*
    When this showAlert function is invoked, we already receive a response from the server, so we can hide the wait page.
    */
    hideWaitPage(); 

    if (now - last_alert_message_timestamp >= interval) {
        if (message == '') {
            // We will only construct the message if it is not already specified.
            // If the message was specified, we will display it "as is"
            let http_error_code = '';
            if (context && context['is_request_error']) {
                // I am not able to simulate a request error even by signing out of the application using a second while editting a task in the first tab.
                // The only way that I am able to simulate a request error is by stopping nginx.
                // And in that case, context['error'] is a string containing the stacktrace.  It seems that the onRequestError hook from ofetch 
                // does not provide us with the HTTP error code, so we will use 503
                http_error_code = 503;
            } else if (context && context['is_response_error']) {
                http_error_code = context['response']['status'];
            }
            message = construct_message_from_http_error_code(http_error_code, context);

            if (http_error_code == 503) {
                // When we use navigateTo to redirect the user to a new page, any pending request is cancelled from 
                // the client side.  Unfortunately, it is treated as error 503, and we do not have any way to detect 
                // this condition, and this is annoying, so we will not show alert for any error 503 for now.
                message = ''
            }

            if ((typeof(window) != 'undefined') && (message != '')) {
                dkAlert(message);
            }
        } else {
            if (typeof(window) != 'undefined') {
                dkAlert(message);
            }
        }
    } else {
        // Because this composable was called within the specific interval from the previous alert, we do not want to display 
        // this alert.
    }

    now = Math.floor(Date.now() / 1000); // In case the user is away or taking a long time to response to the first alert, suppress the second alert if it is within the specific interval.
    last_alert_message_timestamp_state.value = now;  
}
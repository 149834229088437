export const useSettingsStore = defineStore("SettingsStore", {
  state: () => ({
    settings: {},
  }),
  getters: {
    getSetting() {
      if (this.settings.hasOwnProperty('admin_settings')) {
        // Provide default values if they are not set
        if (! this.settings.admin_settings.hasOwnProperty('default_scripting_language')) {
          this.settings.admin_settings.default_scripting_language = 'command'
        }
      }
      return this.settings;
    },
  },
  actions: {
    async setSetting(data) {
      if (data === null) {
        try{
          this.settings = await getSettings();
        }
        catch(e){
          console.error("setSetting failed==========>", e)
          this.settings = {};
        }
      } else {
        this.settings = data
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSettingsStore, import.meta.hot));
}
